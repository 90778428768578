import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en.json'
import es from './locales/es.json'
import ca from './locales/ca.json'
import bg from './locales/bg.json'
import da from './locales/da.json'

Vue.use(VueI18n);

const messages = {
  en,
  es,
  ca,
  bg,
  da
}

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
});
